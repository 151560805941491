<template>
  <v-container class="text-center">
    <br />
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <div class="text-center">
            <h2 class="titulo text-center" v-text="'Datos de cliente'" />
          </div>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="4" class="text-left">
              Nombre: {{ client.nombre + " " + client.apellido_paterno + " " + client.apellido_materno }} <br />
              Fecha de nacimiento: {{ client.fecha_nacimiento }} <br />
              Estado civil: {{ client.estado_civil }} <br />
              ocupación: {{ client.ocupacion }} <br />
            </v-col>
            <v-col cols="4" class="text-left">
              Clave de elector: {{ client.clave_elector }} <br />
              Email: {{ client.email }} <br />
              Teléfono fijo: {{ client.telefono_fijo }} <br />
              Teléfono móvil: {{ client.telefono_fijo }} <br />
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" v-for="(loan, i) in loans" :key="i">
        <Resume-Card :title="'Solicitud ' + loan.idsolicitud_prestamo" :items="loan" :keys="loanKeys" :omitItems="blackList" :loanId="loan.idsolicitud_prestamo">
        <template v-slot:actions>
        <Basic-Btn text="Realizar pago" color="secondary" icon="mdi-cash" @click="redirect(loan.idsolicitud_prestamo)" :loading="loading" />
      </template></Resume-Card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "animate.css";
import services from "@/utils/services";
import { ResumeCard, BasicBtn } from "@/components";

export default {
  name: "detalle-cliente",
  components: {
    ResumeCard,
    BasicBtn,
  },
  data: () => ({
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    detail: { items: ["nombre"] },
    items: [],
    client: {},
    loanKeys: {
      monto_sugerido: "Monto sugerido",
      monto_solicitado: "Monto solicitado",
      monto_aprobado: "Monto aprobado",
      monto_entregado: "Monto entregado",
      ingresos_negocios: "Ingresos de negocios",
      ingresos_empleos: "Ingresos del empleo",
      total_ingresos_semanal: "Total de ingresos semanal",
      total_gastos_semanal: "Total de gastos semanal",
      tipo_tarjeta: "Tipo de tarjeta",
      folio_tarjeta: "Folio  de tarjeta",
      rebajes: "Rebajes",
      observaciones: "Observaciones",
      grupo: "Grupo",
      empresa: "Empresa",
    },
    blackList: ["fecha_creacion", "fecha_actualizacion", "usuario_creacion", "usuario_actualizacion", "idsolicitud_prestamo", "estatus_solicitudes_prestamos"],
    loading: false,
    logo: require("@/assets/logo.png"),
  }),
  computed: {
    loans: {
      get() {
        let array = [];
        for (let info of this.items) {
          array.push(info);
          if (info.empresa) {
            info.empresa = info.empresa.nombre;
          }
          if (info.grupo) {
            info.grupo = info.grupo.nombre;
          }
        }

        return array;
      },
    },
  },
  mounted() {
    this.client = this.$route.query;
    this.getDetail(this.$route.params.id);
  },
  methods: {
    getDetail(id) {
      this.loading = true;
      let request = {
        params: {
          id: id,
        },
      };
      this.axios
        .get(services.routes.client + "/loanRequest", request)
        .then((response) => {
          let data = response.data.data;
          this.items = data;
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },redirect(id){
      this.$router.push('/pagos/' + id)
    },
  },
};
</script>

<style scoped>
.titulo {
  background: #222665;
  color: white;
  padding: 15px;
  display: inline-block;
  border-radius: 0px 0px 20px 20px !important;
  margin-bottom: 1px;
}
</style>
